import { Box, Button, Container, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataService from "../../services/data.services";
import Image from 'mui-image';
import MediaService from "../../services/media.services";
import { UserContext } from "../../UserContext";
import BasicPage from "../Hooks/BasicPage";

const Introduction = () => {
  const { user, setUser, setIsLoading } = useContext(UserContext);
  const [data, setData] = useState(null);
  const [cover, setCover] = useState(null);

  useEffect(() => {
    setIsLoading(true)
    DataService.read("Introduction")
      .then((res) => {
        if (res.data) {
          setData(res.data);
          if (res.data.cover) {
            MediaService.loadImage(res.data.cover)
              .then((res2) => {
                setCover(res2.data);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
        else setData({title: "Click edit button to entry", description: "Not in the database yet."});
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (<>
    <BasicPage data={data} cover={cover} page="introduction" />
  </>);
};

export default Introduction;
